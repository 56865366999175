
// Vue reactivity
import { computed } from 'vue';

// icons
import { } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonRow, IonCol, } from '@ionic/vue';
        
import PageHeader from "@/components/PageHeader.vue";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import MerchantCard from "@/components/MerchantCard.vue";

// services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'MerchantListPage',
  components: { IonPage, IonContent, IonGrid, IonRow, IonCol,
                PageHeader, LoadingSkeleton, MerchantCard, },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppPublicData);
    const allMerchants = computed(() => store.state.allMerchants);

    // 3. return variables & methods to be used in template HTML
    return {
      // icons

      // variables
      loading, allMerchants,

      // methods
      t,
    }
  }
}

